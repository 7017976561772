:root {
  --transform: none;
}
.navMMainOuterDiv {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.65);
  display: flex;
  flex-direction: row;
}
.navMMainInnerDiv {
  width: 35%;
  height: 100%;
  background-color: white;
  animation: move 0.4s 1;
  transform: var(--transform);
}
.navMClosingDiv {
  width: 75%;
  height: 100%;
}
@keyframes move {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0);
  }
}

.navMCloseDiv {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-top: 4%;
  padding-bottom: 4%;
  padding-right: 4%;
}
.navCloseIcon {
  height: 30px;
  color: rgb(80, 80, 80);
  cursor: pointer;
}
.navMProductsInnerDiv {
  display: flex;
  justify-content: space-between;
  padding: 3% 8%;
  align-items: center;
}
.navMLinksdiv {
  display: flex;
  flex-direction: column;
}
.navMLink {
  text-decoration: none;
  color: rgb(80, 80, 80);
  font-size: 18px;
  padding: 5% 8%;
  border-top: 0.1px solid rgb(192, 191, 191);
}
.navMLinkActive {
  background-color: #007cb4;
  color: white;
}
.navMLinkLast {
  border-bottom: 0.1px solid rgb(192, 191, 191);
}
.navMProductsLinkDiv {
  border-top: 0.1px solid rgb(192, 191, 191);
}
.navMProductLink {
  text-decoration: none;
  color: rgb(80, 80, 80);
  font-size: 18px;
}
.navMProductItemLinkActive {
  background-color: #007cb4;
  color: white !important;
}
.navMProductsListDiv {
  display: flex;
  flex-direction: column;
}
.navMProductItemLink {
  text-decoration: none;
  color: rgb(80, 80, 80);
  font-size: 18px;
  padding: 5% 12%;
  border-bottom: 0.1px solid rgb(192, 191, 191);
}
.navMPProductItemLinkLast {
  border-bottom: none;
}
@media screen and (max-width: 540px) {
  .navMMainInnerDiv {
    width: 45%;
  }
  .navMClosingDiv {
    width: 55%;
  }
}
@media screen and (max-width: 480px) {
  .navMMainInnerDiv {
    width: 45%;
  }
  .navMClosingDiv {
    width: 55%;
  }
}
@media screen and (max-width: 375px) {
  .navMMainInnerDiv {
    width: 55%;
  }
  .navMClosingDiv {
    width: 45%;
  }
}
