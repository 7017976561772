/* --------------Template 13------------------- */
::placeholder {
  color: #b4b9c4;
}
.t13MainDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 4%;
  align-items: center;
  /* justify-content: space-between; */
  justify-content: center;
}
.t13FormDiv {
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 3% 5%;
  width: 35%;
  box-shadow: 0.3px 0.3px 2px rgb(101, 101, 101);
}
.t13FormHeading {
  font-size: 26px;
  font-weight: bold;
  text-align: center;
}
.t13FormInputs {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8%;
}
.t13FormInputs input {
  border: none;
  outline: none;
  width: 100%;
  padding: 2%;
  font-family: "Poppins";
  margin-bottom: 8%;
  border-bottom: 1px solid rgb(102, 102, 102);
}
.t13FormInputs textarea {
  resize: none;
  border: none;
  outline: none;
  width: 100%;
  padding: 2%;
  margin-bottom: 4%;
  border-bottom: 1px solid rgb(102, 102, 102);
  font-family: Poppins;
}
.t13Image {
  height: 350px;
}
.t13BgImg1 {
  position: absolute;
  top: 0%;
  left: -3%;
  height: 130px;
}
@media screen and (max-width: 1920px) {
  .t13MainDiv {
    padding: 0;
  }
  .t13Image {
    height: 300px;
  }
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -18%;
    height: 140px;
  }
}
@media screen and (max-width: 1792px) {
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -15%;
    height: 140px;
  }
}
@media screen and (max-width: 1680px) {
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -13%;
    height: 140px;
  }
}
@media screen and (max-width: 1440px) {
  .t13MainDiv {
    padding: 0% 10%;
  }
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -3%;
    height: 140px;
  }
  .t13Image {
    height: 300px;
  }
}
@media screen and (max-width: 1366px) {
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -1%;
    height: 140px;
  }
}
@media screen and (max-width: 1080px) {
  .t13BgImg1 {
    position: absolute;
    top: 0%;
    left: -1%;
    height: 100px;
  }
}
@media screen and (max-width: 768px) {
  .t13MainDiv {
    display: flex;
    justify-content: space-between;

    padding: 2% 2%;
  }
  .t13Image {
    height: 250px;
  }
  .t13BgImg1 {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t13MainDiv {
    display: flex;
    justify-content: space-between;
    padding: 2% 2%;
  }
  .t13FormDiv {
    width: 50%;
  }
  .t13Image {
    height: 150px;
  }
}
@media screen and (max-width: 480px) {
  .t13MainDiv {
    display: flex;
    flex-direction: column-reverse;
  }
  .t13Image {
    height: 200px;
    margin-bottom: 2%;
  }
  .t13FormDiv {
    width: 75%;
  }
}
/* ---------------Template 14------------------ */
.t14MainDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 10%;
}
.t14ContactDiv {
  display: flex;
  flex-direction: column;
}
.t14Heading {
  text-align: center;
  font-size: 26px;
  font-weight: bold;
  width: 75%;
  margin-bottom: 2%;
  margin-right: 5%;
}
.t14BlockDiv {
  display: flex;
  flex-direction: column;
  margin-top: 2%;
  width: 75%;
}
.t14BlocHeading {
  font-weight: bold;
}
.t14Map {
  border: none;
}
.t14BgImg {
  position: absolute;
  top: -30%;
  right: 0;
  height: 130px;
}
@media screen and (max-width: 1920px) {
  .t14MainDiv {
    padding: 0;
  }
  .t14BgImg {
    position: absolute;
    top: -25%;
    right: -18%;
    height: 130px;
  }
}
@media screen and (max-width: 1680px) {
  .t14BgImg {
    position: absolute;
    top: -25%;
    right: -10%;
    height: 130px;
  }
}
@media screen and (max-width: 1440px) {
  .t14MainDiv {
    padding: 0 10%;
  }
  .t14BgImg {
    position: absolute;
    top: -25%;
    right: -2%;
    height: 130px;
  }
}
@media screen and (max-width: 1080px) {
  .t14BgImg {
    position: absolute;
    top: -25%;
    right: 0%;
    height: 100px;
  }
}

@media screen and (max-width: 768px) {
  .t14MainDiv {
    padding: 2% 5%;
  }
  .t14BgImg {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t14Heading {
    font-size: 24px;
    text-align: start;
    text-decoration: underline;
  }
  .t14Block {
    font-size: 12px;
  }
  .t14Map {
    width: 280px;
    height: 250px;
  }
}
@media screen and (max-width: 480px) {
  .t14Map {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 375px) {
  .t14MainDiv {
    display: flex;
    flex-direction: column;
    flex-direction: reverse;

    align-items: flex-start;
  }
  .t14Map {
    width: 300px;
    height: 250px;
    margin-top: 5%;
  }
}
