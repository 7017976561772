/* ------Template-1------- */
.t1MainDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 6%;
}
.t1ContentDiv {
  display: flex;
  flex-direction: column;
  width: 24%;
  margin-left: 10%;
}

.t1Heading {
  font-weight: bold;
  font-size: 40px;
  white-space: pre-line;
  line-height: 40px;
}

.t1Body {
  margin-top: 5%;
  white-space: pre-line;
}

.t1ImgDiv {
  width: 46%;
  margin-right: 10%;
}
.t1ImgDiv img {
  width: 100%;
}

.t1bgImg1 {
  position: absolute;
  top: -15%;
  left: -3%;
  height: 130px;
}
.t1bgImg2 {
  position: absolute;
  top: 100%;
  left: -3%;
  height: 135px;
}

@media screen and (max-width: 1920px) {
  .t1ContentDiv {
    margin-left: 0;
    width: 28%;
  }
  .t1ImgDiv {
    margin-right: 0;
  }
  .t1Heading {
    font-size: 44px;
  }
  .t1Body {
    font-size: 14px;
  }
  .t1ImgDiv {
    width: 52%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -18%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -16%;
    height: 135px;
  }
}
@media screen and (max-width: 1792px) {
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -16%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -15%;
    height: 135px;
  }
}
@media screen and (max-width: 1680px) {
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -12%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -12%;
    height: 135px;
  }
}
@media screen and (max-width: 1440px) {
  .t1ContentDiv {
    margin-left: 10%;
    width: 24%;
  }
  .t1ImgDiv {
    margin-right: 10%;
  }
  .t1ImgDiv {
    width: 46%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -13%;
    left: -1%;
    height: 120px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 90%;
    left: 0%;
    height: 125px;
  }
}

@media screen and (max-width: 1366px) {
  .t1ContentDiv {
    margin-left: 10%;
  }
  .t1ImgDiv {
    margin-right: 10%;
  }
  .t1Heading {
    font-size: 42px;
  }
  .t1Body {
    font-size: 12px;
  }
  .t1ImgDiv {
    width: 46%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -13%;
    left: -1%;
    height: 120px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 90%;
    left: -1%;
    height: 125px;
  }
}
@media screen and (max-width: 1080px) {
  .t1bgImg1 {
    top: -20%;
    left: -1%;
    height: 100px;
  }
  .t1bgImg2 {
    height: 110px;
  }
}
@media screen and (max-width: 768px) {
  .t1MainDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .t1ImgDiv {
    display: none;
  }
  .t1ContentDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0;
  }
  .t1Heading {
    white-space: normal;
    margin: 0;
    padding: 0;
  }
  .t1Body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: center;
    margin-top: 2%;
    font-size: 14px;
  }
  .t1bgImg1 {
    display: none;
  }
  .t1bgImg2 {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t1Heading {
    font-size: 40px;
    margin-bottom: 2%;
  }
  .t1Body {
    margin-bottom: 2%;
    width: 90%;
  }
}
@media screen and (max-width: 480px) {
  .t1Heading {
    font-size: 36px;
  }
  .t1Body {
    font-size: 14px;
    width: 80%;
    margin-top: 3%;
  }
}
@media screen and (max-width: 375px) {
  .t1MainDiv {
    margin-top: 16%;
  }
  .t1Body {
    margin-top: 2%;
  }
}

/* --------Template-2---------- */

.t2MainDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 100%;
  margin-top: 12%;
}
.t2HeadingDiv span {
  font-size: 24px;
  font-weight: bold;
}
.t2ContentDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  margin: 4% 10%;
}
.t2Size2TempContentDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 4% 10%;
}
.t2InfoDiv {
  width: 18%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 5%;
  border-left: 1.8px solid #b6b3b3;
}
.t2Size2TempInfo1Div {
  padding-right: 10%;
}
.t2Size2TempInfo2Div {
  padding-left: 10%;
}
.t2Info1Div {
  border: none;
}
.t2SubHeading {
  font-size: 18px;
  font-weight: bold;
}
.t2Body {
  white-space: pre-line;
  margin-top: 6%;
}
.t2bgImg1 {
  position: absolute;
  top: 90%;
  right: 0;
  height: 130px;
}
@media screen and (max-width: 1920px) {
  .t2HeadingDiv span {
    font-size: 26px;
  }
  .t2SubHeading {
    font-size: 20px;
  }
  .t2Body {
    font-size: 14px;
  }
  .t2bgImg1 {
    top: 80%;
    left: 108%;
  }
  .t2InfoDiv {
    width: 20%;
  }
  .t2ContentDiv {
    margin-right: 0%;
    margin-left: 0%;
  }
}
@media screen and (max-width: 1792px) {
  .t2bgImg1 {
    top: 80%;
    left: 105%;
  }
}
@media screen and (max-width: 1680px) {
  .t2bgImg1 {
    top: 80%;
    left: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .t2ContentDiv {
    margin-right: 10%;
    margin-left: 10%;
  }
  .t2bgImg1 {
    top: 78%;
    left: 90%;
  }
}
@media screen and (max-width: 1366px) {
  .t2HeadingDiv span {
    font-size: 24px;
  }
  .t2SubHeading {
    font-size: 18px;
  }
  .t2Body {
    font-size: 12px;
  }
  .t2bgImg1 {
    top: 80%;
    left: 90%;
  }
  .t2InfoDiv {
    width: 18%;
  }
  .t2ContentDiv {
    margin-right: 10%;
    margin-left: 10%;
  }
}
@media screen and (max-width: 1080px) {
  .t2bgImg1 {
    top: 70%;
    height: 105px;
    left: 90%;
  }
}
@media screen and (max-width: 768px) {
  .t2MainDiv {
    display: flex;
    align-items: flex-start;
  }
  .t2HeadingDiv {
    width: 100%;
  }
  .t2HeadingDiv span {
    text-align: start;
    margin-left: 4%;
  }
  .t2ContentDiv {
    flex-direction: column;
    margin: 0;
    align-items: flex-start;
    margin-left: 4%;
    margin-top: 4%;
  }
  .t2Size2TempContentDiv {
    flex-direction: column;
    margin: 0;
    margin-top: 4%;
    justify-content: center;
  }
  .t2Size2TempInfo1Div {
    padding-right: 0%;
  }
  .t2Size2TempInfo2Div {
    padding-right: 0%;
  }
  .t2InfoDiv {
    border: none;
    width: 80%;
    margin-bottom: 4%;
    margin-left: 0;
    padding-left: 0%;
  }

  .t2Body {
    margin-top: 1%;
    font-size: 14px;
  }
  .t2bgImg1 {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t2HeadingDiv {
    display: flex;
  }
  .t2HeadingDiv span {
    font-size: 22px;
  }
  .t2InfoDiv {
    margin-bottom: 6%;
  }
  .t2SubHeading {
    font-size: 18px;
    margin-bottom: 1%;
  }
  .t2Body {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .t2HeadingDiv {
    display: flex;
  }

  .t2HeadingDiv span {
    font-size: 22px;
  }
  .t2SubHeading {
    font-size: 16px;
  }
  .t2ContentDiv {
    margin-top: 2%;
  }
  .t2Body {
    font-size: 14px;
  }
  .t2InfoDiv {
    margin-top: 4%;
  }
}
@media screen and (max-width: 375px) {
  .t2HeadingDiv {
    margin-top: 10%;
  }
  .t2Body {
    font-size: 14px;
  }
}

/* ----------Template-3-------------- */
.t3MainDiv {
  position: relative;
  width: 100%;
  margin-top: 8%;
}
.t3HeadingDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.t3HeadingText {
  font-size: 24px;
  font-weight: bold;
}
.t3DescriptionText {
  font-size: 12px;
  text-align: center;
  width: 30%;
}
.t3ContentDiv {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
}

.t3InfoMainDiv {
  display: flex;
  flex-direction: column;
  background-color: #d7e8f4;
  width: 20%;
  padding-right: 2%;
  min-height: 200px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
}
.t3InfoMainDiv img {
  height: 48px;
  width: 48px;
  transform: translateY(-20px);
  margin-left: 6%;
}
.t3SubHead {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid;
  width: fit-content;
  white-space: pre-line;
  margin-left: 6%;
}
.t3SubDesc {
  margin-top: 10%;
  margin-bottom: 10%;
  white-space: pre-line;
  font-size: 12px;
  margin-left: 6%;
}
.t3bgImg1 {
  position: absolute;
  top: 65%;
  left: -3%;
  height: 125px;
}
@media screen and (max-width: 1920px) {
  .t3ContentDiv {
    padding: 0;
  }
  .t3HeadingText {
    font-size: 26px;
  }
  .t3DescriptionText {
    font-size: 14px;
  }
  .t3InfoMainDiv {
    width: 18%;
  }
  .t3SubHead {
    font-size: 18px;
  }
  .t3SubDesc {
    font-size: 14px;
  }
  .t3bgImg1 {
    top: 60%;
    left: -18%;
    height: 125px;
  }
  .t3InfoMainDiv {
    min-height: 230px;
  }
}
@media screen and (max-width: 1792px) {
  .t3bgImg1 {
    top: 60%;
    left: -15%;
    height: 125px;
  }
}
@media screen and (max-width: 1680px) {
  .t3bgImg1 {
    top: 60%;
    left: -12%;
    height: 125px;
  }
  .t3InfoMainDiv {
    min-height: 325px;
  }
}
@media screen and (max-width: 1440px) {
  .t3ContentDiv {
    padding-right: 5%;
    padding-left: 5%;
  }

  .t3bgImg1 {
    top: 80%;
    left: -1%;
  }
}
@media screen and (max-width: 1366px) {
  .t3bgImg1 {
    position: absolute;
    top: 65%;
    left: -1%;
    height: 110px;
  }
  .t3ContentDiv {
    padding-right: 10%;
    padding-left: 10%;
  }
  .t3HeadingText {
    font-size: 24px;
  }
  .t3DescriptionText {
    font-size: 12px;
  }
  .t3InfoMainDiv {
    width: 20%;
    min-height: 300px;
  }
  .t3SubHead {
    font-size: 16px;
  }
  .t3SubDesc {
    font-size: 12px;
  }
  .t3bgImg1 {
    top: 60%;
    left: -1%;
    height: 125px;
  }
}
@media screen and (max-width: 1080px) {
  .t3bgImg1 {
    height: 105px;
  }
}
@media screen and (max-width: 768px) {
  .t3bgImg1 {
    display: none;
  }
  .t3HeadingDiv {
    display: flex;
    align-items: flex-start;
  }
  .t3HeadingText {
    margin-left: 4%;
  }

  .t3DescriptionText {
    font-size: 14px;
    width: 60%;
    text-align: start;
    margin-left: 4%;
  }
  .t3ContentDiv {
    display: flex;
    flex-direction: row;
    height: fit-content;
    padding: 0;
    margin-top: 4%;
  }
  .t3InfoMainDiv {
    width: 100%;
    box-sizing: border-box;
    height: 260px;
    margin: 0;
    margin-left: 20px;
    margin-right: 25px;
  }
  .t3SubDesc {
    margin-top: 3%;
    font-size: 14px;
  }
}
@media screen and (max-width: 540px) {
  .t3ContentDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .t3InfoMainDiv {
    width: 80%;
    height: fit-content;
    min-height: 280px;
    margin-bottom: 8%;
  }
  .t3DescriptionText {
    width: 90%;
    margin-bottom: 4%;
    font-size: 14px;
  }

  .t3SubDesc {
    margin-top: 1%;
    margin-bottom: 2%;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .t3HeadingText {
    font-size: 22px;
  }
  .t3DescriptionText {
    font-size: 14px;
    width: 80%;
  }
  .t3SubHead {
    font-size: 16px;
  }
  .t3SubDesc {
    font-size: 14px;
  }
  .t3InfoMainDiv {
    margin-top: 5%;
  }
}
@media screen and (max-width: 375px) {
  .t3MainDiv {
    margin-top: 18%;
  }
  .t3HeadingText {
    margin-left: 5%;
  }

  .t3DescriptionText {
    margin-left: 5%;
    font-size: 14px;
  }
}

/* ----------Template-4---------- */
.t4MainDiv {
  position: relative;
  display: flex;
  flex-direction: column;
  font-size: 12px;
  width: 100%;
  margin-top: 12%;
}
.t4HeadingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.t4Heading {
  font-size: 24px;
  font-weight: bold;
}
.t4HeadingDescription {
  font-size: 12px;
  text-align: center;
  width: 30%;
}
.t4ContentDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  margin: 4% 10%;
}

.t4InfoDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* width: 100%; */
  margin-bottom: 4%;
}
.t4Image {
  height: 140px;
  width: 140px;
}
.t4InfoImageLeftDiv {
  margin-right: 10%;
}
.t4InfoImageLeftDiv img {
  padding-right: 12%;
}
.t4InfoImageRightDiv {
  flex-direction: row-reverse;
  margin-left: 10%;
}
.t4InfoImageRightDiv img {
  padding-left: 12%;
}
.t4ContentDescriptionDiv {
  display: flex;
  flex-direction: column;
  background-color: #d6e6f2;
  width: 350px;
  /* here------------------ */
  padding: 1% 2%;
  box-shadow: 0.5px 0.5px 2px rgb(101, 101, 101);
}
.t4SubHeading {
  font-size: 18px;
  font-weight: bold;
  border-bottom: 1px solid;
  width: fit-content;
}
.t4Description {
  white-space: pre-line;
  margin-top: 5%;
  width: 100%;
  /* here----------------- */
}
.t4bgImg1 {
  position: absolute;
  top: 0;
  left: 8%;
}
.t4bgImg1 {
  position: absolute;
  top: 40%;
  left: 90%;
  height: 135px;
}
@media screen and (max-width: 1920px) {
  .t4Heading {
    font-size: 26px;
  }
  .t4HeadingDescription {
    font-size: 14px;
  }
  .t4SubHeading {
    font-size: 20px;
  }
  .t4Description {
    font-size: 14px;
  }
  .t4ContentDescriptionDiv {
    padding: 2% 3%;
  }
  .t4ContentDiv {
    margin-left: 0%;
    margin-right: 0%;
  }
  .t4bgImg1 {
    top: 50%;
    left: 108%;
  }
}
@media screen and (max-width: 1792px) {
  .t4bgImg1 {
    top: 50%;
    left: 107%;
  }
}
@media screen and (max-width: 1680px) {
  .t4bgImg1 {
    top: 50%;
    left: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .t4ContentDiv {
    margin-left: 10%;
    margin-right: 10%;
  }
  .t4bgImg1 {
    position: absolute;
    top: 40%;
    left: 92%;
    height: 125px;
  }
}

@media screen and (max-width: 1366px) {
  .t4Heading {
    font-size: 24px;
  }
  .t4HeadingDescription {
    font-size: 12px;
  }
  .t4SubHeading {
    font-size: 18px;
  }
  .t4Description {
    font-size: 12px;
  }
  .t4ContentDescriptionDiv {
    padding: 1% 2%;
  }
  .t4ContentDiv {
    margin-left: 10%;
    margin-right: 10%;
  }

  .t4bgImg1 {
    position: absolute;
    top: 40%;
    left: 90%;
    height: 125px;
  }
}

@media screen and (max-width: 1080px) {
  .t4bgImg1 {
    top: 40%;
    height: 105px;
    left: 90%;
  }
}

@media screen and (max-width: 768px) {
  .t4ContentDiv {
    margin: 5% 0%;
  }

  .t4HeadingDescription {
    width: 60%;
    font-size: 14px;
  }
  .t4InfoDiv {
    margin-bottom: 5%;
  }
  .t4Image {
    height: 120px;
    width: 120px;
  }
  .t4Description {
    width: 100%;
    font-size: 14px;
    margin-bottom: 2%;
  }
  .t4ContentDescriptionDiv {
    padding: 2.5% 2%;
  }
  .t4InfoImageLeftDiv img {
    padding-right: 8%;
  }
  .t4InfoImageRightDiv img {
    padding-left: 8%;
  }
  .t4bgImg1 {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t4HeadingDescription {
    font-size: 14px;
    width: 90%;
  }
  .t4SubHeading {
    font-size: 16px;
  }
  .t4Description {
    font-size: 14px;
  }
  .t4InfoImageLeftDiv {
    margin-bottom: 10%;
  }
  .t4InfoImageRightDiv {
    margin-bottom: 10%;
  }
  .t4InfoImageLeftDiv img {
    padding-right: 4%;
  }
  .t4InfoImageRightDiv img {
    padding-left: 4%;
  }
  .t4ContentDescriptionDiv {
    width: 250px;
  }
}
@media screen and (max-width: 480px) {
  .t4Heading {
    font-size: 22px;
  }
  .t4HeadingDescription {
    font-size: 14px;
  }
  .t4SubHeading {
    font-size: 16px;
  }
  .t4Description {
    font-size: 14px;
  }
  .t4InfoDiv {
    margin-top: 5%;
  }
  .t4Image {
    height: 125px;
    width: 125px;
  }
  .t4ContentDescriptionDiv {
    width: 250px;
  }
  .t4InfoImageLeftDiv {
    margin-right: 2%;
  }
  .t4InfoImageLeftDiv img {
    padding-right: 6%;
  }
  .t4InfoImageRightDiv {
    flex-direction: row-reverse;
    margin-left: 2%;
  }
  .t4InfoImageRightDiv img {
    padding-left: 6%;
  }
}
@media screen and (max-width: 375px) {
  .t4MainDiv {
    margin-top: 18%;
  }
  .t4HeadingDescription {
    font-size: 14px;
  }
  .t4InfoDiv {
    margin-top: 5%;
  }
  .t4ContentDiv {
    margin-top: 8%;
  }
  .t4ContentDescriptionDiv {
    width: 200px;
  }
  .t4Image {
    height: 130px;
    width: 130px;
  }
}

/* ----------Template-5-------- */
.t5MainDiv {
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 6%;
  align-items: center;
}
.t5HeadingDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.t5HeadingText {
  font-weight: bold;
  font-size: 24px;
}
.t5HeadingDescriptionText {
  font-size: 12px;
}
.t5ContentDiv {
  margin-top: 2%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  box-sizing: border-box;
  padding-left: 5%;
  padding-right: 5%;
  flex-wrap: wrap;
  width: 70%;
}
.clientImages {
  height: 96px;
  width: 250px;
  margin-bottom: 15%;
}
.t5bgImg1 {
  position: absolute;
  top: 40%;
  left: 0%;
  height: 130px;
}
@media screen and (max-width: 1920px) {
  .t5HeadingText {
    font-size: 26px;
  }
  .t5ContentDiv {
    margin-top: 5%;
    padding: 0;
  }
  .t5HeadingDescriptionText {
    font-size: 14px;
  }
  .img {
    height: 120px;
  }
  .t5bgImg1 {
    position: absolute;
    top: 42%;
    left: -14%;
    height: 130px;
  }
}
@media screen and (max-width: 1680px) {
  .t5bgImg1 {
    position: absolute;
    top: 42%;
    left: -10%;
    height: 130px;
  }
}
@media screen and (max-width: 1440px) {
  .t5bgImg1 {
    position: absolute;
    top: 40%;
    left: 0%;
    height: 120px;
  }
  .t5ContentDiv {
    margin-top: 2%;
    padding: 0 5%;
  }
}

@media screen and (max-width: 1366px) {
  .t5HeadingText {
    font-size: 24px;
  }
  .t5ContentDiv {
    margin-top: 2%;
    padding: 0 5%;
  }
  .t5HeadingDescriptionText {
    font-size: 12px;
  }
  .img {
    height: 96px;
  }
  .t5bgImg1 {
    position: absolute;
    top: 40%;
    left: 2%;
    height: 120px;
  }
}
@media screen and (max-width: 1080px) {
  .t5bgImg1 {
    height: 105px;
  }
}
@media screen and (max-width: 768px) {
  .t5bgImg1 {
    display: none;
  }
  .t5HeadingDescriptionText {
    font-size: 14px;
  }
  .t5ContentDiv {
    margin-top: 6%;
    flex-direction: row;
    width: 100%;
  }
  .img {
    height: 96px;
  }
  .clientImages {
    height: 80px;
    width: 150px;
  }
}
@media screen and (max-width: 540px) {
  .t5HeadingDescriptionText {
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .t5HeadingText {
    font-size: 22px;
  }
  .t5ContentDiv {
    margin-top: 8%;
    padding: 0;
  }
  .t5HeadingDescriptionText {
    font-size: 14px;
  }
  .img {
    height: 100px;
  }
  .clientImages {
    height: 50px;
    width: 125px;
  }
}
@media screen and (max-width: 375px) {
  .t5HeadingText {
    margin-top: 18%;
  }
  .t5HeadingDescriptionText {
    font-size: 14px;
  }
  .t5ContentDiv {
    margin-top: 10%;
    padding: 0;
  }
  .img {
    height: 85px;
  }
}

/* -----Template-6---------- */
element.style {
  display: flex;
}
.t6MainDiv {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10%;
}
.t6SubMainDiv {
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.t6HeadingText {
  font-size: 24px;
  font-weight: bold;
}
.t6OutSliderDiv {
  width: 100%;
}
.t6InSliderDiv {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 450px;
}

.t6PicAndNameDiv {
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: center;
}
.t6ClientImg {
  height: 64px;
  width: 64px;
}
.t6EachTestimonialDiv {
  display: flex;
  flex-direction: row;
  margin: 4%;
  background-color: #f7faff;
  box-shadow: 0.5px 0.5px 2px rgb(101, 101, 101);
  padding: 4%;
  width: 65%;
  align-items: center;
  height: 100px;
}
.t6OddDiv {
  flex-direction: row-reverse;
  margin-left: 20%;
}
.t6EvenDiv {
  margin-right: 20%;
}
.t6EachTestimonialDivReviewText {
  width: 70%;
  font-size: 12px;
  margin-left: 5%;
}
.t6EvenDiv .t6EachTestimonialDivReviewText {
  margin-left: 5%;
}
.t6OddDiv .t6EachTestimonialDivReviewText {
  margin-right: 5%;
}
.t6GivenByName {
  display: flex;
  justify-content: center;
  width: 100px;
  text-align: center;
  font-weight: bold;
}
.t6ClientNameText {
  display: flex;
  justify-content: center;
  width: 100px;
  text-align: center;
  font-size: 12px;
}
.slick-track {
  height: fit-content;
}
.prevNextImgs {
  background-color: #f7faff;
}
.t6bgImg1 {
  position: absolute;
  top: 45%;
  right: -1%;
  height: 125px;
}
@media screen and (max-width: 1920px) {
  .t6OddDiv {
    margin-left: 10%;
    padding-top: 2%;
  }
  .t6EvenDiv {
    margin-right: 10%;
    padding-bottom: 2%;
  }
  .t6EachTestimonialDivReviewText {
    font-size: 14px;
  }
  .t6HeadingText {
    font-size: 26px;
  }
  .t6EachTestimonialDiv {
    padding: 6%;
  }
  .t6InSliderDiv {
    height: 500px;
  }
  .t6bgImg1 {
    top: 48%;
    left: 105%;
  }
}
@media screen and (max-width: 1792px) {
  .t6bgImg1 {
    top: 48%;
    left: 104%;
  }
}
@media screen and (max-width: 1680px) {
  .t6bgImg1 {
    top: 48%;
    left: 100%;
  }
}
@media screen and (max-width: 1440px) {
  .t6bgImg1 {
    top: 50%;
    left: 90%;
    height: 110px;
  }
  .t6OddDiv {
    margin-left: 20%;
  }
  .t6EvenDiv {
    margin-right: 20%;
  }
}
@media screen and (max-width: 1366px) {
  .t6OddDiv {
    margin-left: 20%;
  }
  .t6EvenDiv {
    margin-right: 20%;
  }
  .t6EachTestimonialDivReviewText {
    font-size: 12px;
  }
  .t6HeadingText {
    font-size: 24px;
  }
  .t6EachTestimonialDiv {
    padding: 4%;
  }
  .t6InSliderDiv {
    height: 450px;
  }
  .t6bgImg1 {
    top: 50%;
    left: 88%;
    height: 110px;
  }
}
@media screen and (max-width: 1080px) {
  .t6bgImg1 {
    height: 105px;
  }
}
@media screen and (max-width: 768px) {
  .t6bgImg1 {
    display: none;
  }
  .t6HeadingText {
    font-size: 24px;
  }
  .t6SubMainDiv {
    width: 600px;
  }
  .t6OutSliderDiv {
    width: 100%;
  }
  .t6InSliderDiv {
    height: 300px;
  }
  .t6EachTestimonialDiv {
    width: 80%;
    height: 150px;
    box-shadow: 0.3px 0.3px 2px rgb(101, 101, 101);
  }
  .t6EachTestimonialDivReviewText {
    font-size: 14px;
  }
}
@media screen and (max-width: 540px) {
  .t6HeadingText {
    margin-bottom: 10%;
  }
  .t6SubMainDiv {
    width: 400px;
  }
  .t6InSliderDiv {
    height: 400px;
  }
  .t6ClientImg {
    height: 72px;
    width: 72px;
  }
  .t6EachTestimonialDiv {
    height: 500px;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
  .t6ClientNameText {
    font-weight: bold;
    margin-bottom: 20%;
  }
  .t6EachTestimonialDivReviewText {
    width: 90%;
  }
  .t6MainDiv {
    margin-bottom: 15%;
  }
}
@media screen and (max-width: 414px) {
  .t6HeadingText {
    font-size: 22px;
  }
  .t6EachTestimonialDivReviewText {
    font-size: 12px;
    text-align: center;
    margin: 0;
  }
  .t6InSliderDiv {
    height: 350px;
  }
  .t6HeadingText {
    margin-bottom: 5%;
  }
}
@media screen and (max-width: 375px) {
  .t6HeadingText {
    margin-top: 18%;
  }
  .t6EachTestimonialDiv {
    width: 60%;
  }
  .t6EachTestimonialDivReviewText {
    font-size: 13px;
  }
  .t6InSliderDiv {
    height: 400px;
  }
}
