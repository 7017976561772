body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}
.App {
  background-color: #f7faff;
  display: flex;
  justify-content: center;
  overflow-x: hidden;
}
.AppNoScroll {
  height: 90vh;
  overflow-y: hidden;
}
.Display1366Div {
  width: 1366px;
  box-sizing: border-box;
  padding: 0 1%;
}
.DisplayDiv {
  width: 100%;
}
