.button {
  background-color: #007cb4;
  color: white;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 300;
  width: fit-content;
  padding: 6px 15px;
  margin-top: 5%;
  border: none;
  transition-duration: 0.15s;
}
.button:hover {
  background-color: #1d97ce;
  cursor: pointer;
}

@media screen and (max-width: 1920px) {
  .button {
    font-size: 14px;
    padding: 8px 18px;
  }
}

@media screen and (max-width: 1366px) {
  .button {
    font-size: 12px;
    padding: 6px 15px;
  }
}
@media screen and (max-width: 1366px) {
  .button {
    margin-top: 2%;
    padding: 9px 18px;
    font-size: 14px;
  }
}
@media screen and (max-width: 480px) {
  .button {
    margin-top: 3%;
    font-size: 12px;
  }
}
