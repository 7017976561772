.footMMainDiv {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  font-size: 12px;
  box-sizing: border-box;
  margin-top: 6%;
  margin-bottom: 2%;
  padding: 2% 5%;
  border-top: 0.5px solid #d1d0d0;
}
.footMIntroHeadingDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
}
.footMIntroImg {
  height: 40px;
  width: 40px;
}
.footMIntroHeading {
  font-size: 22px;
  font-weight: bold;
  margin-left: 2.5%;
}
.footMIntroDescription {
  white-space: pre-line;
  margin-left: 3%;
}
.footMCompanyLinks {
  font-size: 10px;
  margin-top: 12%;
  display: flex;
  width: fit-content;
  justify-content: space-between;
}
.footMCompanyLink {
  text-decoration: none;
  color: black;
}
.footMCompanyLinks span {
  margin: 0 8px;
  cursor: pointer;
}
.footMCompanyLinks span:hover {
  color: #1d97ce;
}
.footMContactDiv {
  display: flex;
  flex-direction: column;
}
.footMContactDiv span {
  margin-top: 2%;
}
.footMContactHeading {
  font-weight: bold;
  font-size: 16px;
}
.footMContactAddress {
  white-space: pre-line;
}
.footMSocialDiv {
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin-top: 3%;
}
.footMSocialDiv img {
  height: 28px;
  width: 28px;
  margin-right: 15%;
  cursor: pointer;
}
@media screen and (max-width: 540px) {
  .footMMainDiv {
    padding: 1%;
    padding-top: 2%;
  }
  .footMDiv2 {
    width: 40%;
  }
  .footMIntroImg {
    height: 36px;
    width: 36px;
  }
  .footMIntroHeading {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .footMMainDiv {
    padding: 4% 2%;
  }
  .footMIntroImg {
    height: 25px;
    width: 25px;
  }
  .footMIntroHeading {
    font-size: 18px;
  }
  .footMCompanyLinks {
    margin-top: 16%;
  }
  .footMCompanyLinks span {
    margin: 0;
    margin-right: 6px;
  }
  .footMDiv1 {
    width: 50%;
  }
  .footMDiv2 {
    /* justify-content: flex-end; */
    width: 40%;
  }
  .footMContactDiv span {
    margin-top: 1%;
    font-size: 10px;
  }
  .footMContactHeading {
    font-size: 14px !important;
  }
  .footMSocialDiv img {
    height: 25px;
    width: 25px;
  }
}
