.t11MainDiv {
  position: relative;
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 4%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.t11Heading {
  font-weight: bold;
  font-size: 44px;
  white-space: pre-line;
  line-height: 55px;
}
.t11Image {
  height: 300px;
}
.t11BgImg1 {
  position: absolute;
  top: 0%;
  left: -3%;
  height: 130px;
}
@media screen and (max-width: 1920px) {
  .t11MainDiv {
    padding: 0;
  }
  .t11Image {
    height: 380px;
  }
  .t11BgImg1 {
    top: 8%;
    left: -20%;
  }
}
@media screen and (max-width: 1792px) {
  .t11Image {
    height: 350px;
  }
  .t11BgImg1 {
    top: 8%;
    left: -15%;
  }
}
@media screen and (max-width: 1680px) {
  .t11BgImg1 {
    top: -2%;
    left: -12%;
    height: 120px;
  }
}
@media screen and (max-width: 1440px) {
  .t11MainDiv {
    padding-left: 10%;
    padding-right: 10%;
  }
  .t11Image {
    height: 300px;
  }
  .t11BgImg1 {
    top: 0%;
    left: -1%;
    height: 90px;
  }
}
@media screen and (max-width: 1080px) {
  .t11Image {
    height: 280px;
  }
  .t11BgImg1 {
    top: 0%;
    left: -1%;
    height: 90px;
  }
}
@media screen and (max-width: 768px) {
  .t11BgImg1,
  .img,
  .singleImg,
  .multipleImg {
    display: none;
  }
  .t11MainDiv {
    flex-direction: row-reverse;
    padding: 0 0;
    padding-left: 5%;
  }
  .t11Image {
    height: 200px;
    padding-right: 5%;
  }
}
@media screen and (max-width: 540px) {
  .t11MainDiv {
    flex-direction: row-reverse;
    padding: 0 3%;
    align-items: center;
    justify-content: center;
    margin-top: 5%;
  }
  .t11Image {
    height: 150px;
    padding-right: 6%;
  }
  .t11Heading {
    font-size: 32px;
    line-height: 42px;
  }
}
@media screen and (max-width: 480px) {
  .t11Image {
    height: 120px;
    padding-right: 6%;
  }
  .t11Heading {
    font-size: 30px;
  }
}
@media screen and (max-width: 390px) {
  .t11MainDiv {
    margin-bottom: 45px;
    margin-top: 14%;
  }
  .t11Image {
    height: 100px;
    padding-right: 2%;
  }
  .t11Heading {
    font-size: 28px;
    line-height: 36px;
  }
}
/* -------------------------------- */
.t12MainDiv {
  margin-top: 8%;
  display: flex;
  flex-direction: row;
  padding-left: 10%;
  padding-right: 10%;
  justify-content: space-between;
  height: fit-content;
  min-height: 100px;
}
.t12SubDiv1 {
  background-color: #deeef7;
  width: 320px;
  position: relative;
  display: flex;
  justify-content: flex-end;
}
.t12SubDiv1SubDiv {
  position: absolute;
  right: 50px;
  top: -20px;
}
.t12SubDiv2 {
  width: 60%;
}
.t12HeadingText {
  font-size: 28px;
  font-weight: bold;
  white-space: pre-line;
}
.t12Bottombar {
  height: 4px;
  width: 34%;
  background-color: black;
}
.t12DescriptionText {
  font-size: 16px;
}
.t12ReadMoreButton {
  font-size: 14px;
  text-decoration: underline;
  color: #007cb4;
  cursor: pointer;
}
.t12ReadMoreButton:hover {
  color: #0291d3;
}
@media screen and (max-width: 1920px) {
  .t12SubDiv1 {
    width: 350px;
  }
}
@media screen and (max-width: 1440px) {
  .t12SubDiv1 {
    width: 330px;
  }
}

@media screen and (max-width: 1080px) {
  .t12SubDiv1 {
    width: 280px;
  }
}

@media screen and (max-width: 768px) {
  .t12MainDiv {
    padding-left: 5%;
    padding-right: 5%;
  }
  .t12SubDiv1 {
    width: 250px;
  }
  .t12SubDiv1SubDiv {
    position: absolute;
    right: 20px;
    top: -20px;
  }
}
@media screen and (max-width: 540px) {
  .t12MainDiv {
    margin-top: 10%;
  }
  .t12HeadingText {
    font-size: 20px;
  }
  .t12DescriptionText {
    font-size: 14px;
  }
  .t12SubDiv1 {
    height: 100px;
    width: 220px;
    margin-right: 4%;
  }
}
@media screen and (max-width: 375px) {
  .t12MainDiv {
    flex-direction: column;
  }
  .t12SubDiv1 {
    height: 80px;
    padding: 2% 0% 0% 2%;
    width: 190px;
    margin-bottom: 2%;
  }
  .t12SubDiv2 {
    width: 100%;
  }
  .t12SubDiv1 {
    justify-content: flex-start;
  }
  .t12SubDiv1SubDiv {
    position: static;
  }
}
/* -------------------------------------- */
.t8MainDiv {
  display: flex;
  justify-content: space-between;
  /* padding-left: 10%;
  padding-right: 10%; */
  margin-top: 8%;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
}
.single {
  display: flex;
  justify-content: flex-end;
}
.img {
  display: none;
}

.t8SubDiv {
  width: 35%;
  border-left: 5px solid #007cb4;
  padding-left: 2%;
  padding-top: 1.5%;
  padding-bottom: 1.5%;
  min-height: 450px;
  background-color: white;
  box-shadow: 0.3px 0.3px 1px rgb(101, 101, 101);
  padding-right: 2%;
  margin-bottom: 5%;
}
.t8SubDivSingle {
  padding-bottom: 3%;
  width: 70%;
  background-color: white;
  box-shadow: 0.3px 0.3px 2px rgb(101, 101, 101);
}
.t8Heading {
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 2%;
  white-space: pre-line;
}
.t8Bottombar {
  height: 4px;
  width: 10%;
  background-color: black;
  margin-bottom: 5%;
}
.t8Description {
  white-space: pre-line;
  font-size: 14px;
  margin-top: 2%;
}
.t8ReadMoreButton {
  font-size: 14px;
  text-decoration: underline;
  color: #007cb4;
  cursor: pointer;
}

@media screen and (max-width: 1920px) {
  .t8MainDiv {
    padding: 0 1%;
  }
  .t8Description {
    font-size: 16px;
    padding-top: 50px;
    margin-top: 50px;
  }
  .t8Heading {
    font-size: 26px;
  }
  .t8SubDiv {
    min-height: 380px;
  }
  .singleImg {
    position: absolute;
    top: 40%;
    left: -15%;
    height: 120px;
  }
  .multipleImg {
    position: absolute;
    top: 40%;
    right: -20%;
    height: 120px;
  }
}
@media screen and (max-width: 1792px) {
  .singleImg {
    position: absolute;
    top: 40%;
    left: -12%;
    height: 120px;
  }
  .multipleImg {
    position: absolute;
    top: 40%;
    right: -15%;
    height: 120px;
  }
}
@media screen and (max-width: 1680px) {
  .singleImg {
    position: absolute;
    top: 40%;
    left: -7%;
    height: 120px;
  }
  .multipleImg {
    position: absolute;
    top: 40%;
    right: -10%;
    height: 120px;
  }
}

@media screen and (max-width: 1440px) {
  .t8MainDiv {
    padding-left: 10%;
    padding-right: 10%;
  }
  .t8SubDiv {
    min-height: 400px;
  }
  .singleImg {
    position: absolute;
    top: 40%;
    left: 0%;
    height: 120px;
  }
  .multipleImg {
    position: absolute;
    top: 40%;
    right: -2%;
    height: 120px;
  }
}

@media screen and (max-width: 1366px) {
  .t8MainDiv {
    padding-left: 10%;
    padding-right: 10%;
  }
  .t8Description {
    font-size: 14px;
  }
  .t8Heading {
    font-size: 24px;
  }
  .t8SubDiv {
    min-height: 350px;
  }
  .singleImg {
    position: absolute;
    top: 40%;
    left: 0%;
    height: 120px;
  }
  .multipleImg {
    position: absolute;
    top: 40%;
    right: -2%;
    height: 120px;
  }
}

@media screen and (max-width: 768px) {
  .t8MainDiv {
    display: flex;
    flex-direction: row;
    padding: 0 5%;
  }
  .t8SubDiv {
    width: 90%;
  }
}
@media screen and (max-width: 540px) {
  .t8Heading {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .t8SubDiv {
    margin-bottom: 8%;
  }
}
@media screen and (max-width: 375px) {
  .t8MainDiv {
    margin-top: 14%;
  }
  .t8SubDiv {
    margin-bottom: 10%;
  }
}
/* -------------------------------------- */
.buttonDiv {
  margin-left: 10%;
}
@media screen and (min-width: 1441px) {
  .buttonDiv {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .buttonDiv {
    margin-left: 5%;
  }
}
