body {
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
:root {
  --display: none;
}
.navMainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5%;
  font-size: 14px;
  cursor: pointer;
}
.navMainDiv img {
  height: 40px;
  width: 40px;
}
.navLinksDiv {
  display: flex;
  flex-direction: row;
  width: 55%;
  justify-content: flex-end;
}

.navLink {
  margin-left: 4%;
  color: #b4b9c4;
  text-decoration: none;
}
.navLink:hover {
  color: #9ea3ad;
}
.navLinkProducts {
  color: #b4b9c4;
  cursor: default;
  text-decoration: none;
  display: flex;
}
.navLinkProducts:hover {
  color: #9ea3ad;
}
.navActiveLink {
  margin-left: 4%;
  font-weight: bold;
  color: #007cb4 !important;
  cursor: default;
  text-decoration: none;
  border-bottom: 2px solid #007cb4;
}
.navProductsDownArrow {
  height: 100px;
}
.navProductsDiv {
  position: relative;
  margin-left: 4%;
}
.productsDropDownMainDiv {
  position: absolute;
  display: var(--display);
  flex-direction: column;
  background-color: #d6e6f2;
  border-top: 5px solid #f7faff;
  z-index: 1000;
}
.productsListItem {
  cursor: pointer;
  font-size: 12px;
  padding: 10px 80px 10px 18px;
  text-decoration: none;
  color: black;
}
.productsListItem:hover {
  background-color: #007cb4;
  color: white;
}

@media screen and (max-width: 768px) {
  .navMainDiv {
    background-color: #f7faff;
    box-shadow: 0.2px 0.2px 1px #afafb1;
    padding-top: 2%;
    margin: 0;
  }
  .navMainDiv img {
    margin-left: 5%;
    margin-left: 30%;
  }
  .navBurgerMenuDiv {
    padding-right: 1%;
    margin-right: 5%;
  }
}
@media screen and (max-width: 480px) {
  .navMainDiv img {
    height: 35px;
    width: 35px;
    margin-left: 30%;
  }
  .navBurgerMenuDiv {
    margin-right: 5%;
  }
}
@media screen and (max-width: 375px) {
  .navMainDiv img {
    height: 32px;
    width: 32px;
    margin-left: 30%;
  }
  .navBurgerMenuDiv {
    margin-right: 5%;
  }
}
