.footM375MainDiv {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  box-sizing: border-box;
  margin-top: 8%;
  margin-bottom: 2%;
  padding: 2% 5%;
  padding-top: 6%;
  border-top: 1px solid #d1d0d0;
}
.footM375IntroDiv {
  display: flex;
  flex-direction: column;
  width: 70%;
}
.footM375IntroHeadingDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
}
.footM375IntroImg {
  height: 30px;
  width: 30px;
}
.footM375IntroHeading {
  font-size: 22px;
  font-weight: bold;
  margin-left: 1%;
}
.footM375IntroDescription {
  white-space: pre-line;
  margin-top: 2%;
  margin-left: 1%;
}
.footM375CompanyLinks {
  font-size: 10px;
  margin-top: 6%;
  margin-bottom: 6%;
  display: flex;
  width: fit-content;
  justify-content: space-between;
}
.footM375CompanyOuterLinks {
  width: 100%;
  display: flex;
  justify-content: center;
}

.footM375CompanyLinks span {
  margin: 0 8px;
  cursor: pointer;
}
.footM375CompanyLink {
  text-decoration: none;
  color: black;
}
.footM375CompanyLinks span:hover {
  color: #1d97ce;
}
.footM375ContactDiv {
  display: flex;
  flex-direction: column;
  width: 70%;
  margin-top: 5%;
}
.footM375ContactDiv span {
  margin-top: 3%;
}
.footM375ContactHeading {
  font-weight: bold;
  font-size: 16px;
  margin-top: 5%;
}
.footM375ContactAddress {
  white-space: pre-line;
}
.footM375SocialDiv {
  width: fit-content;
  display: flex;
  width: 30%;
  justify-content: space-between;
  margin-top: 8%;
}
.footM375SocialOuterDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}
.footM375SocialDiv img {
  height: 28px;
  width: 28px;
  /* margin-right: 15%; */
  cursor: pointer;
}
