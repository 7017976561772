@media screen and (max-width: 1440px) {
  .TOSMainDiv {
    margin-left: 10%;
    margin-right: 10%;
  }
}
@media screen and (max-width: 1366px) {
  .mainHeading {
    font-size: 26px;
  }

  .descriptionInfo {
    font-size: 14px;
  }
}
@media screen and (max-width: 768px) {
  .TOSMainDiv {
    margin-left: 4%;
  }
}
@media screen and (max-width: 375px) {
  .mainHeading {
    font-size: 24px;
  }
  .descriptionInfo {
    font-size: 12px;
  }
}
