.footMainDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  box-sizing: border-box;
  margin-top: 2%;
  padding: 5% 5% 3% 5%;
}
.footIntroDiv {
  width: 20%;
}
.footIntroHeadingDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 3%;
}
.footIntroImg {
  height: 40px;
  width: 40px;
}
.footIntroHeading {
  font-size: 22px;
  font-weight: bold;
  margin-left: 2.5%;
}
.footIntroDescription {
  white-space: pre-line;
  margin-left: 3%;
}
.footLinksDiv {
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footSocialDiv {
  width: fit-content;
  display: flex;
  justify-content: space-between;
}
.footSocialDiv img {
  height: 30px;
  width: 30px;
  margin: 0 6px;
  cursor: pointer;
}
.footCompanyLinks {
  font-size: 10px;
  margin-top: 4%;
  display: flex;
  width: fit-content;
  justify-content: space-between;
}
.footCompanyLink {
  text-decoration: none;
  color: black;
}
.footCompanyLinks span {
  margin: 0 8px;
  cursor: pointer;
}
.footCompanyLinks span:hover {
  color: #1d97ce;
}
.footContactDiv {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.footContactDiv span {
  margin-top: 2%;
}
.footContactHeading {
  font-weight: bold;
  font-size: 16px;
}
.footContactAddress {
  white-space: pre-line;
}

@media screen and (max-width: 1920px) {
  .footMainDiv {
    padding: 5% 0% 3% 0%;
  }
  .footIntroHeading {
    font-size: 24px;
  }
  .footIntroImg {
    height: 44px;
  }
  .footIntroDescription {
    font-size: 14px;
  }
  .footSocialDiv img {
    height: 34px;
    width: 34px;
  }
  .footCompanyLinks {
    font-size: 12px;
  }
  .footContactDiv {
    font-size: 14px;
  }
  .footContactHeading {
    font-size: 20px;
  }
}
@media screen and (max-width: 1366px) {
  .footMainDiv {
    padding: 5% 5% 3% 5%;
  }
  .footIntroHeading {
    font-size: 22px;
  }
  .footIntroImg {
    height: 40px;
  }
  .footIntroDescription {
    font-size: 12px;
  }
  .footSocialDiv img {
    height: 30px;
    width: 30px;
  }
  .footCompanyLinks {
    font-size: 10px;
  }
  .footContactDiv {
    font-size: 12px;
  }
  .footContactHeading {
    font-size: 16px;
  }
}
