.productsBannerDiv {
  position: relative;
}
.productsBgBlockImg {
  position: absolute;
  top: 5%;
  left: -3%;
  height: 130px;
}
.productsBgBannerImg {
  height: 350px;
  margin-top: 30px;
  margin-left: 50%;
}
.productsBgImg {
  position: absolute;
  top: 260px;
  left: 0;
  width: 100%;
}
.productsLogoNTitleDiv {
  display: flex;
  position: absolute;
  top: 210px;
  left: 10%;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
}
.productsTitleDiv {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-top: 60px;
}
.productsTitle {
  font-size: 32px;
  width: 200px;
  font-weight: bold;
  line-height: 40px;
}
.productsDescription {
  margin-top: 5px;
  width: 260px;
  font-size: 14px;
}
.productsLogo {
  height: 200px;
}
.productsTemplatesDiv {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
}
.productsTemplatesDiv1366 {
  width: 1366px;
}
.productsTemplatesDiv {
  width: 100%;
}
.productsMHeaderDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  padding: 0% 2.5%;
  margin-top: 4%;
}
.productsMLogo {
  height: 160px;
}
.productsMTitleNDescriptionDiv {
  display: flex;
  flex-direction: column;
  margin-left: 2%;
}
.productsMTitle {
  font-size: 32px;
  font-weight: bold;
}
.productsMDescription {
  margin-top: 1%;
  margin-right: 5%;
}
@media screen and (max-width: 1920px) {
  .productsBgBlockImg {
    position: absolute;
    top: 4%;
    left: -20%;
    height: 130px;
  }
  .productsBgBannerImg {
    height: 360px;
    margin-top: 35px;
    margin-left: 54%;
  }
  .productsLogoNTitleDiv {
    display: flex;
    position: absolute;
    top: 210px;
    left: 15%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
  }
  .productsTitle {
    font-size: 34px;
  }
  .productsDescription {
    font-size: 16px;
  }
  .productsLogo {
    height: 210px;
  }
}
@media screen and (max-width: 1792px) {
  .productsLogoNTitleDiv {
    left: 12%;
  }
  .productsBgBlockImg {
    position: absolute;
    top: 5%;
    left: -15%;
    height: 130px;
  }
}
@media screen and (max-width: 1680px) {
  .productsBgBlockImg {
    position: absolute;
    top: 6%;
    left: -10%;
    height: 130px;
  }
}
@media screen and (max-width: 1440px) {
  .productsBgBlockImg {
    position: absolute;
    top: 5%;
    left: -1%;
  }
}

@media screen and (max-width: 1366px) {
  .productsBgBlockImg {
    position: absolute;
    top: 5%;
    left: -1%;
    height: 130px;
  }
  .productsBgBannerImg {
    height: 350px;
    margin-top: 30px;
    margin-left: 50%;
  }
  .productsLogoNTitleDiv {
    display: flex;
    position: absolute;
    top: 210px;
    left: 10%;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    z-index: 1000;
  }
  .productsTitle {
    font-size: 30px;
  }
  .productsDescription {
    font-size: 14px;
  }
  .productsLogo {
    height: 200px;
  }
}
@media screen and (max-width: 1080px) {
  .productsBgBlockImg {
    position: absolute;
    top: 5%;
    left: -1%;
    height: 110px;
  }
  .productsBgBannerImg {
    height: 330px;
    margin-top: 30px;
    margin-left: 50%;
  }
}
@media screen and (max-width: 768px) {
  .productsTemplatesDiv {
    background-color: #f7faff;
  }
}
@media screen and (max-width: 480px) {
  .productsMHeaderDiv {
    margin-top: 6%;
  }
}
@media screen and (max-width: 375px) {
  .productsMHeaderDiv {
    margin-top: 14%;
  }
}
/* -----------------Template 7--------------------- */
.t7MainDiv {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0% 10%;
  margin-top: 3%;
}
.t7Title {
  font-size: 24px;
  font-weight: bold;
}
.t7Body {
  font-size: 14px;
  margin-top: 1%;
}

@media screen and (max-width: 1920px) {
  .t7MainDiv {
    padding: 0 1%;
  }
  .t7Title {
    font-size: 26px;
  }
  .t7Body {
    font-size: 16px;
  }
}
@media screen and (max-width: 1440px) {
  .t7MainDiv {
    padding: 0 10%;
  }
}
@media screen and (max-width: 1366px) {
  .t7MainDiv {
    padding: 0 10%;
  }
  .t7Title {
    font-size: 24px;
  }
  .t7Body {
    font-size: 14px;
    line-height: 24px;
  }
}
@media screen and (max-width: 768px) {
  .t7MainDiv {
    padding: 0 5%;
  }
}
@media screen and (max-width: 480px) {
  .t7MainDiv {
    margin-top: 8%;
  }
}
@media screen and (max-width: 375px) {
  .t7MainDiv {
    margin-top: 14%;
  }
}
/* ------------------Template 10----------------- */
.t10MainDiv {
  padding-left: 10%;
  padding-right: 10%;
  margin-top: 8%;
  display: flex;
  justify-content: center;
}
.videoPlayer {
  height: 360px !important;
}
@media screen and (max-width: 480px) {
  .t10MainDiv {
    margin-top: 12%;
  }
  .videoPlayer {
    height: 280px !important;
  }
}
@media screen and (max-width: 375px) {
  .t10MainDiv {
    margin-top: 14%;
  }
}
/* --------------Template 8---------------------   */
/* template 8 taken from collaboration.css */
/* -------------Template 9-------------- */
.t9MainDiv {
  padding-left: 10%;
  padding-right: 10%;
  /* margin-top: 8%; */
  display: flex;
  flex-direction: column;
}
.t9button {
  height: 45px;
  cursor: pointer;
  margin-top: 8%;
}
@media screen and (max-width: 1920px) {
  .t9MainDiv {
    padding: 0 1%;
  }
  .t9button {
    height: 50px;
  }
}

@media screen and (max-width: 1440px) {
  .t9MainDiv {
    padding: 0 10%;
  }
}
@media screen and (max-width: 1366px) {
  .t9MainDiv {
    padding: 0 10%;
  }
  .t9button {
    height: 45px;
  }
}
@media screen and (max-width: 768px) {
  .t9MainDiv {
    padding: 0 5%;
  }
}
@media screen and (max-width: 480px) {
  .t9MainDiv {
    margin-top: 4%;
  }
  .t9button {
    height: 40px;
  }
}
@media screen and (max-width: 375px) {
  .t9MainDiv {
    margin-top: 8%;
  }
  .t9button {
    height: 32px;
  }
}
