/* ---------------Template  1----------------- */
.t1MainDiv {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  font-size: 12px;
  margin-top: 6%;
}
.t1ContentDiv {
  display: flex;
  flex-direction: column;
  width: 24%;
  margin-left: 10%;
}

.t1Heading {
  font-weight: bold;
  font-size: 40px;
  white-space: pre-line;
  line-height: 40px;
}

.t1Body {
  margin-top: 5%;
  white-space: pre-line;
}

.t1ImgDiv {
  width: 46%;
  margin-right: 10%;
}
.t1ImgDiv img {
  width: 100%;
}

.t1bgImg1 {
  position: absolute;
  top: -15%;
  left: -3%;
  height: 130px;
}
.t1bgImg2 {
  position: absolute;
  top: 100%;
  left: -3%;
  height: 135px;
}

@media screen and (max-width: 1920px) {
  .t1ContentDiv {
    margin-left: 0;
  }
  .t1ImgDiv {
    margin-right: 0;
  }
  .t1Heading {
    font-size: 44px;
  }
  .t1Body {
    font-size: 14px;
  }
  .t1ImgDiv {
    width: 52%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -18%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -16%;
    height: 135px;
  }
}
@media screen and (max-width: 1792px) {
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -16%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -15%;
    height: 135px;
  }
}
@media screen and (max-width: 1680px) {
  .t1bgImg1 {
    position: absolute;
    top: -14%;
    left: -12%;
    height: 130px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 80%;
    left: -12%;
    height: 135px;
  }
}
@media screen and (max-width: 1440px) {
  .t1ContentDiv {
    margin-left: 10%;
  }
  .t1ImgDiv {
    margin-right: 10%;
  }
  .t1ImgDiv {
    width: 46%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -13%;
    left: -1%;
    height: 120px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 90%;
    left: 0%;
    height: 125px;
  }
}

@media screen and (max-width: 1366px) {
  .t1ContentDiv {
    margin-left: 10%;
  }
  .t1ImgDiv {
    margin-right: 10%;
  }
  .t1Heading {
    font-size: 42px;
  }
  .t1Body {
    font-size: 12px;
  }
  .t1ImgDiv {
    width: 46%;
  }
  .t1bgImg1 {
    position: absolute;
    top: -13%;
    left: -1%;
    height: 120px;
  }
  .t1bgImg2 {
    position: absolute;
    top: 90%;
    left: -1%;
    height: 125px;
  }
}
@media screen and (max-width: 1080px) {
  .t1bgImg1 {
    top: -20%;
    left: -1%;
    height: 100px;
  }
  .t1bgImg2 {
    height: 110px;
  }
}
@media screen and (max-width: 768px) {
  .t1MainDiv {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .t1ImgDiv {
    display: none;
  }
  .t1ContentDiv {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    margin: 0;
  }
  .t1Heading {
    white-space: normal;
    margin: 0;
    padding: 0;
  }
  .t1Body {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
    text-align: center;
    margin-top: 2%;
    font-size: 14px;
  }
  .t1bgImg1 {
    display: none;
  }
  .t1bgImg2 {
    display: none;
  }
}
@media screen and (max-width: 540px) {
  .t1ContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: 5%;
  }
  .t1Heading {
    font-size: 40px;
    margin-bottom: 2%;
  }
  .t1Body {
    margin-bottom: 2%;
    width: 90%;
    text-align: left;
  }
}
@media screen and (max-width: 480px) {
  .t1Heading {
    font-size: 36px;
  }
  .t1Body {
    font-size: 12px;
    width: 90%;
    margin-top: 3%;
  }
}
@media screen and (max-width: 375px) {
  .t1MainDiv {
    margin-top: 16%;
  }
  .t1Body {
    margin-top: 2%;
  }
}
/* --------------Template 3---------------- */
.t3MainDiv {
  position: relative;
  width: 100%;
  margin-top: 8%;
}
.t3HeadingDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.t3HeadingText {
  font-size: 24px;
  font-weight: bold;
}
.t3DescriptionText {
  font-size: 12px;
  text-align: center;
  width: 30%;
}
.t3ContentDiv {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding-left: 10%;
  padding-right: 10%;
  width: 100%;
}

.t3InfoMainDiv {
  display: flex;
  flex-direction: column;
  background-color: #d7e8f4;
  width: 20%;
  padding-right: 2%;
  min-height: 200px;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  padding-left: 2%;
}
.t3InfoMainDiv img {
  height: 48px;
  width: 48px;
  transform: translateY(-20px);
  margin-left: 6%;
}
.t3SubHead {
  font-size: 16px;
  font-weight: bold;
  border-bottom: 1px solid;
  width: fit-content;
  white-space: pre-line;
  margin-left: 6%;
}
.t3SubDesc {
  margin-top: 10%;
  margin-bottom: 10%;
  white-space: pre-line;
  font-size: 12px;
  margin-left: 6%;
}
.t3bgImg1 {
  position: absolute;
  top: 65%;
  left: -3%;
  height: 125px;
}
@media screen and (max-width: 1920px) {
  .t3ContentDiv {
    padding: 0;
  }
  .t3HeadingText {
    font-size: 26px;
  }
  .t3DescriptionText {
    font-size: 14px;
  }
  .t3InfoMainDiv {
    width: 18%;
  }
  .t3SubHead {
    font-size: 18px;
  }
  .t3SubDesc {
    font-size: 14px;
  }
  .t3bgImg1 {
    top: 60%;
    left: -18%;
    height: 125px;
  }
  .t3InfoMainDiv {
    min-height: 230px;
  }
}
@media screen and (max-width: 1792px) {
  .t3bgImg1 {
    top: 60%;
    left: -15%;
    height: 125px;
  }
}
@media screen and (max-width: 1680px) {
  .t3bgImg1 {
    top: 60%;
    left: -12%;
    height: 125px;
  }
}
@media screen and (max-width: 1440px) {
  .t3ContentDiv {
    padding-right: 5%;
    padding-left: 5%;
  }
  .t3bgImg1 {
    top: 80%;
    left: -1%;
  }
}
@media screen and (max-width: 1366px) {
  .t3bgImg1 {
    position: absolute;
    top: 65%;
    left: -1%;
    height: 110px;
  }
  .t3ContentDiv {
    padding-right: 10%;
    padding-left: 10%;
  }
  .t3HeadingText {
    font-size: 24px;
  }
  .t3DescriptionText {
    font-size: 12px;
  }
  .t3InfoMainDiv {
    width: 20%;
  }
  .t3SubHead {
    font-size: 16px;
  }
  .t3SubDesc {
    font-size: 12px;
  }
  .t3bgImg1 {
    top: 60%;
    left: -1%;
    height: 125px;
  }
}
@media screen and (max-width: 1080px) {
  .t3bgImg1 {
    height: 105px;
  }
}
@media screen and (max-width: 768px) {
  .t3bgImg1 {
    display: none;
  }

  .t3DescriptionText {
    font-size: 14px;
    width: 60%;
  }
  .t3ContentDiv {
    flex-direction: column;
  }
  .t3InfoMainDiv {
    width: 80%;
    margin-bottom: 8%;
    box-sizing: border-box;
    padding-left: 2%;
  }
  .t3SubDesc {
    margin-top: 3%;
    font-size: 14px;
  }
}
@media screen and (max-width: 540px) {
  .t3HeadingDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .t3DescriptionText {
    width: 90%;
    margin-bottom: 4%;
    text-align: left;
  }
  .t3ContentDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 2%;
  }
  .t3InfoMainDiv {
    margin-bottom: 12%;
  }
}
@media screen and (max-width: 480px) {
  .t3HeadingText {
    font-size: 22px;
  }
  .t3DescriptionText {
    font-size: 12px;
    width: 75%;
  }
  .t3SubHead {
    font-size: 14px;
  }
  .t3SubDesc {
    font-size: 12px;
  }
  .t3InfoMainDiv {
    margin-top: 5%;
  }
}
@media screen and (max-width: 375px) {
  .t3MainDiv {
    margin-top: 18%;
  }
}
